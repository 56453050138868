import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import {
  decorReverseLink,
  decorHoverReverseLink,
  slantLeft,
  slantLeftFallback,
  slantRight,
  slantRightFallback,
  transition,
} from '../../common/mixins'
import theme from '../../themes/theme'
import media from '../../common/MediaQueries'
import { TRUNCATED_HERO_SLANT_TYPES } from '../../constants/truncatedHeroTypes'

const StyledFeaturedPostWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledFeaturedPostImg = styled.div`
  position: relative;
  width: 100%;
  height: 21rem;
  overflow: hidden;

  @media ${media.phone} {
    height: 45.125rem;
  }

  @media ${media.tablet} {
    ${({ slantType }) =>
      slantType === TRUNCATED_HERO_SLANT_TYPES.left &&
      css`
        ${slantLeft}
        ${slantLeftFallback({
          background: `${theme.colors.white}`,
        })}
      `}

    ${({ slantType }) =>
      slantType === TRUNCATED_HERO_SLANT_TYPES.right &&
      css`
        ${slantRight}
        ${slantRightFallback({
          background: `${theme.colors.white}`,
        })}
      `}
  }
`

const StyledFeaturedPostAction = styled.p`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.yellow};
  margin-top: 1.25rem;
  ${decorReverseLink}
`

const StyledFeaturedPostLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: calc(${({ theme }) => theme.sizes.inner} - 1.875rem * 2);
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.875rem;

  h3 {
    ${transition}
  }

  &:hover {
    h3 {
      color: ${({ theme }) => theme.colors.yellow};
    }

    ${StyledFeaturedPostAction} {
      ${decorHoverReverseLink}
    }
  }

  @media ${media.phone} {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2.875rem;
  }

  @media ${media.tablet} {
    padding-top: 3.75rem;
    padding-bottom: 3rem;
  }
`

const StyledFeaturedPostContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 47.9375rem;
`

export {
  StyledFeaturedPostWrapper,
  StyledFeaturedPostImg,
  StyledFeaturedPostLink,
  StyledFeaturedPostContent,
  StyledFeaturedPostAction,
}
