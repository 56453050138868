import React from 'react'
import { graphql } from 'gatsby'
import _first from 'lodash/first'
import { useInView } from 'react-intersection-observer'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import LatestNews from '../components/LatestNews/LatestNews'
import FeaturedPost from '../components/FeaturedPost/FeaturedPost'
import Newsletter from '../components/Forms/Newsletter/Newsletter'
import { HEADER_TYPES } from '../constants/headerTypes'
import { BG_TYPES } from '../constants/bgTypes'
import mapNewsToList from '../common/queries/mapNewsToList'
import { newsletterSpacings, bgTheme } from '../common/utils'

const NewsTemplate = ({ pageContext, data }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const { newsList } = pageContext
  const posts = mapNewsToList(data.wp.posts.edges)
  const latestNews = _first(posts)
  const acf = data.wp.page?.newslistAcf || {}
  const seoData = data.wp.page?.seo
  const news = mapNewsToList(newsList)
  const image = acf.heroImage
    ? acf.heroImage.imageFile?.childImageSharp.fluid
    : latestNews.fluid

  const newsletterBg = bgTheme(acf.newsletterContainerBackground)
  const newsletterSpacing = newsletterSpacings(acf.newsletterBoxMargins)

  return (
    <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={BG_TYPES.lightGrey}
        mt={['4.875rem', '', '0']}
        pb={['', '2.8275rem']}
      >
        <FeaturedPost
          date={latestNews.date}
          title={latestNews.label}
          url={latestNews.to}
          images={image}
          slantType="right"
        />
      </Container>

      <Container
        pt={newsletterSpacing}
        pb={newsletterSpacing}
        bg={newsletterBg}
      >
        <Inner pl={['0', '1.875rem']} pr={['0', '1.875rem']}>
          <Newsletter />
        </Inner>
      </Container>

      <Container
        pb={['2.875rem', '', '6.25rem']}
        bg={BG_TYPES.lightGrey}
        inView={inView}
      >
        <Inner>
          <div ref={ref}>
            {inView ? (
              <LatestNews anim={false} news={news.slice(1)} hasLoadMore />
            ) : null}
          </div>
        </Inner>
      </Container>
    </Layout>
  )
}

export default NewsTemplate

export const query = graphql`
  query {
    wp {
      page(id: "news", idType: URI) {
        newslistAcf {
          heroImage {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          newsletterBoxMargins
          newsletterContainerBackground
        }
        seo {
          ...seoFragment
        }
      }
      posts(first: 1) {
        edges {
          node {
            id
            slug
            title
            date
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
