import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledFeaturedPostWrapper,
  StyledFeaturedPostImg,
  StyledFeaturedPostLink,
  StyledFeaturedPostContent,
  StyledFeaturedPostAction,
} from './styles'
import BlogHeading from '../BlogHeading/BlogHeading'
import Image from '../Image/Image'
import { fluidPropType } from '../../constants/proptypes'
import { formatDate } from '../../common/utils'
import { TRUNCATED_HERO_SLANT_TYPES } from '../../constants/truncatedHeroTypes'

const FeaturedPost = ({ date, title, url, images, slantType }) => {
  const newDate = new Date(date)

  return (
    <StyledFeaturedPostWrapper>
      <StyledFeaturedPostImg slantType={slantType}>
        <Image fluid={images} />
      </StyledFeaturedPostImg>
      <StyledFeaturedPostLink to={url}>
        <StyledFeaturedPostContent className="uiAnimBottom">
          <BlogHeading {...{ title }} date={formatDate(newDate)} />
          <StyledFeaturedPostAction>Read Article</StyledFeaturedPostAction>
        </StyledFeaturedPostContent>
      </StyledFeaturedPostLink>
    </StyledFeaturedPostWrapper>
  )
}

export default FeaturedPost

FeaturedPost.defaultProps = {
  slantType: TRUNCATED_HERO_SLANT_TYPES.left,
}

FeaturedPost.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  images: PropTypes.oneOfType([
    fluidPropType,
    PropTypes.arrayOf(fluidPropType),
  ]),
  slantType: PropTypes.oneOf(Object.keys(TRUNCATED_HERO_SLANT_TYPES)),
}
